import {
  COMPOSITE_ACCESS_LEVELS,
  COMPOSITE_SERVICES,
  MARKET_CODES,
} from '../types/basic.enums'
import {
  type DatalayerContent,
  type DatalayerOrg,
  type DatalayerBrowser,
  type DataLayerAuthEvent,
  AuthFormStatus,
  AuthStatusEvent,
} from '../types/tracking'
import type { Tag } from '../types/helperTypes'
import { useDeviceSize } from './Device'
import { getLocaleDate } from '../utils/datetimeUtils'
import { useContentStore } from '../stores/basic'
import { safeURL } from '../utils/urlUtils'
// TODO don't forget about FORM events
export default function useTracking() {
  const { onElementScroll, scrollPosition } = useScrollHandler()

  const trackPageView = async (): Promise<void> => {
    const config = useRuntimeConfig()
    const appConfig = useAppConfig()
    const userStore = useUserStore()

    const marketCode = config?.public.MARKET_CODE as string
    const authList = appConfig?.authentification || false

    const user = userStore?.user
    const auth = authList?.[marketCode as keyof typeof authList] || false

    const dataReady: DatalayerDataReady = {
      event: 'page view',
      environment: {
        isProduction: (process.env.SITE_ENV as string) === 'production',
        platform: {
          name: 'willow-frontend',
        },
        dataSource: 'web',
      },
      org: getOrg(),
      browser: getBrowser(),
      device: getDevice(),
      content: getContent(),
      user: auth
        ? {
            id: {
              system: 'cognito',
              value: user?.sub || '',
            },
            isLoggedIn: !!user,
            isSubscriber: !!user?.sub || false,
          }
        : {
            id: {
              system: 'cognito',
              value: '',
            },
            isLoggedIn: false,
            isSubscriber: false,
          },
      referrer: getReferrer(),
    }
    resetData()
    pushData(dataReady)
  }

  const trackClick = (data: any): void => {
    const fullUrl = safeURL(data?.url)?.href
    const content = useContentStore()

    const searchQuery =
      data?.el?.target?.closest?.('[data-search]')?.dataset?.search || null

    const searchEvent =
      data?.el?.target?.closest?.('[data-event]')?.dataset?.event || null

    // el.closest('[data-position]')?.dataset?.position || undefined

    const eventParams = searchQuery
      ? {
          category: 'search',
          searchQuery: searchQuery,
        }
      : undefined

    const dataReady: DatalayerDataReady = {
      event: searchEvent || 'link click',
      engagement: {
        type: 'link click',
        component: getComponent(data?.el.target),
        destination: {
          url: fullUrl || null,
          title: data?.title || null,
          type: data?.type || null,
        },
      },
    }

    if (eventParams) {
      dataReady.eventParams = eventParams
    }

    if (dataReady.engagement.component.type === undefined) {
      dataReady.engagement.component.type = 'Link'
    }

    if ((content?.content as any)?.is_front_page) {
      dataReady.engagement.component.position = 'frontpage'
    }

    if (
      dataReady.engagement.component.type === 'Teaser List' &&
      content.pageType === 'category'
    ) {
      dataReady.engagement.component.position = 'category'
    }

    if (
      dataReady.engagement.component.type === 'Teaser List' &&
      content.pageType === 'content'
    ) {
      dataReady.engagement.component.position = 'below content'
    }

    pushData(dataReady)
    if (searchQuery) {
      resetSearchClick()
    } else {
      resetInteractionEvent()
    }
  }

  const trackInteraction = (
    interactionType: string,
    componentPosition: string | null = null,
    componentName: string | null = null,
    componentType: string | null = null,
    componentIndex: string | null = null,
    componentRange: string | null = null,
    query: string | null = null
  ) => {
    const searchQuery =
      (document.querySelector('[data-search]') as HTMLElement)?.dataset
        ?.search || query

    const dataReady: DatalayerDataReady = {
      event: `interaction ${interactionType}`,
      engagement: {
        type: interactionType,
        component: {
          position: componentPosition,
          name: componentName,
          type: componentType,
          ...(componentIndex !== null && { index: String(componentIndex) }),
          ...(componentRange !== null && { range: String(componentRange) }),
        },
      },
    }
    if (searchQuery || interactionType === 'search submit') {
      dataReady.event = 'search submit'
      dataReady.engagement.type = 'search'
      dataReady.eventParams = {
        category: 'search',
        searchQuery: searchQuery,
      }
    }

    pushData(dataReady)
    resetInteractionEvent()
  }

  const trackAuthEvent = (
    event: AuthStatusEvent,
    engagementType: AuthFormStatus,
    componentName: string | null = null,
    componentPosition: string | null = null,
    errorMessage: string | null = null
  ) => {
    const categoryType =
      event === AuthStatusEvent.LOGIN_SUCCESS ||
      event === AuthStatusEvent.LOGIN_SUBMIT ||
      event === AuthStatusEvent.LOGIN_FAILURE
        ? 'login'
        : 'logout'

    const eventData: DataLayerAuthEvent = {
      event: event,
      engagement: {
        type: engagementType,
        component: {
          name: componentName || '',
          position: componentPosition || '',
          eventParams: {
            category: categoryType,
            errorMessage: errorMessage || undefined,
          },
        },
      },
    }
    pushData(eventData)
  }

  const pushData = (data: DatalayerDataReady) => {
    if (process.client) {
      if (!window.dataLayer) window.dataLayer = []
      window.dataLayer?.push(data)
    }
  }

  const resetData = () => {
    const defaultData = {
      content: undefined,
      engagement: undefined,
    }
    if (process.client) {
      window.dataLayer?.push(defaultData)
    }
  }

  const resetScroll = () => {
    const defaultData = {
      eventParams: undefined,
    }
    if (process.client) {
      window.dataLayer?.push(defaultData)
    }
  }

  const resetInteractionEvent = () => {
    const defaultData = {
      event: undefined,
      engagement: undefined,
    }
    if (process.client) {
      window.dataLayer?.push(defaultData)
    }
  }

  const resetSearchClick = () => {
    const defaultData = {
      event: undefined,
      engagement: undefined,
      eventParams: undefined,
    }
    if (process.client) {
      window.dataLayer?.push(defaultData)
    }
  }

  const trackScrollBehaviour = (element: HTMLElement) => {
    const thresholds = [0, 25, 50, 75, 90, 100]
    const reachedThresholds = ref(new Set<number>())

    const createTrackingObject = (percentage: number) => {
      return {
        event: 'article scroll',
        eventParams: {
          scrollDepth: `${percentage}`,
          scrollMeasure: 'percent',
        },
      }
    }

    onElementScroll(element, (event: Event, position: number): void => {
      const clientHeight = element.clientHeight
      const clientPercentage = clientHeight / 100

      const scrollPercentage =
        (position +
          Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
          )) /
        clientPercentage

      thresholds.forEach(threshold => {
        if (
          scrollPercentage >= threshold &&
          !reachedThresholds.value.has(threshold)
        ) {
          reachedThresholds.value.add(threshold)
          const trackingObject = createTrackingObject(threshold)

          pushData(trackingObject)
          resetScroll()
          // You can send the trackingObject to your tracking service here
        }
      })
    })
  }
  return {
    trackPageView,
    trackClick,
    trackInteraction,
    trackScrollBehaviour,
    trackAuthEvent,
  }
}

// Helper methods below ---------------
const getComponent = (el: any) => {
  if (!el) return 'unset'
  const position = el.closest('[data-position]')?.dataset?.position || undefined
  const type =
    el.closest('[data-component-type]')?.dataset?.componentType || undefined
  const name =
    el.closest('[data-component-name]')?.dataset?.componentName || undefined
  return { type, name, position }
}

const getOrg = (): DatalayerOrg => {
  const config = useRuntimeConfig()
  const marketCode = config.public.MARKET_CODE as string
  return {
    brandCode: config.public.BRAND_CODE as string,
    subBrand: undefined,
    market: MARKET_CODES[marketCode as keyof typeof MARKET_CODES],
    businessUnit: 'Bonnier Publications',
    service: COMPOSITE_SERVICES['maincontent'], // TODO check other
  }
}

const getBrowser = (): DatalayerBrowser => {
  const { viewport } = useDeviceSize()
  return { viewport }
}

const getContent = (): DatalayerContent | undefined => {
  const { content, pageType } = storeToRefs(useContentStore())
  const { getExternalTags, getInternalTags } = useContentStore()

  const hiddenTags = (getInternalTags() || []).map(
    (tag: { slug: string }) => tag.slug
  )

  let contentAccessStatus = ''

  if (hiddenTags.includes('premium')) {
    contentAccessStatus = 'locked'
  } else if (hiddenTags.includes('free')) {
    contentAccessStatus = 'open'
  }

  const timeAt = getTimeAt(content?.value)

  return {
    index: '1',
    location: window.location.href,
    section: getContentSections(content),
    id: {
      value: content.value.id,
      system: 'willow-whitelabel',
    },
    type: getArticleType(content, pageType),
    isStandalone: true,
    title: content.value.title,
    trigger: 'page load',
    commercialType: 'editorial', // TODO check other and also for non composites
    wordCount: content.value.word_count,
    publicationDate: timeAt.publish || undefined,
    lastModifiedDate: timeAt.update || undefined,
    author: content.value.author?.name || undefined,
    category: content.value.category?.data.translations?.da?.title || undefined,
    topCategory:
      content.value.category?.data?.ancestor?.data.translations?.da?.title ||
      undefined,
    tags: getExternalTags().map((tag: Tag) => tag.name),
    tagsHidden: getInternalTags().map((tag: Tag) => tag.name),
    editorialType: content.editorial_type,
    contentAccessStatus: contentAccessStatus, // Updated logic applied here

    template:
      content.value.template && content.value.template.length > 0
        ? content.value.template
        : 'default',
  }
}

const getDevice = () => {
  const { screen } = useDeviceSize()
  return { screen }
}

const getTimeAt = (content: any) => {
  return {
    publish: getLocaleDate(content?.published_at, 'YYYY-MM-DD'),
    update: getLocaleDate(content?.updated_at, 'YYYY-MM-DD'),
  }
}

const getArticleType = (content: any, pageType: any) => {
  const templateType = [
    'article',
    'frontpage',
    'login',
    'search',
    'signup',
    'video-page',
    'authors',
    'author',
    'favourites',
    'profile',
  ]
  if (templateType.includes(content.value.template)) {
    return content.value.template
  }

  if (pageType?.value === 'content') return 'article'

  return pageType?.value || 'article'
}

const getContentSections = (content: any) => {
  if (!content.value.category?.data) return []

  const mainCategory = content.value.category?.data.name
  const sectionArray = [mainCategory]

  if (content.value.category?.data.parent) {
    sectionArray.push(content.value.category?.data.parent?.data.name)
  }

  const ancestor = content.value.category?.data.ancestor?.data.name

  if (ancestor) {
    sectionArray.push(ancestor)
  }

  return sectionArray
}

const getReferrer = (): string | undefined => {
  if (window.currentRefferalRoute) {
    const value = window.currentRefferalRoute
    if (value === 'direct') {
      return undefined
    }
    if (value) {
      return value
    }
  }
  return undefined
}
